.MuiTabs-flexContainer{
    background-color: #000000;
}

.MuiGrid-root{
    background-color: transparent;
}

.MuiBox-root{
    background-color: transparent;
}

/* body{
    background-color: transparent;
} */

