video {
  width: 100%;
  height: 90%;
  object-fit: cover;
  border-radius: 5px;
}

.video-box {
  width: 100%;
  height: 90%;
  object-fit: cover;
}
