.btn-switch {
  display: inline-block;
  text-decoration: none;
  display: block;
  margin-block-start: 1.5em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  margin-left: 10px;
  display: inline-block;
}

.btn-nav {
  display: inline;
}

.MuiButtonBase-root{
  background-color: white;
}

.react-select-drops{
  margin-left: 5px;
  margin-right: 5px;
}