.container-quill {
  /* padding: 10px; */
  margin-top: 10px;
}

.container-quill .ql-toolbar {
  /* justify-content: center; */
  display: flex;
  border: none;
  box-shadow: 0 0 1px 0 rgb(0, 0, 0, 0.5);
}

.container-quill .ql-container {
  margin-top: 10px;
  border: none;
  box-shadow: 0 0 1px 0 rgb(0, 0, 0, 0.5);
}

.container-quill .ql-container .ql-editor {
  min-height: 80vh;
}

.container-quill .ql-container .ql-snow {
  display: flex;
  border: none;
  /* justify-content: center; */
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="8px"]::before {
  content: "8px";
  font-size: 8px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="10px"]::before {
  content: "10px";
  font-size: 10px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before {
  content: "12px";
  font-size: 12px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
  content: "14px";
  font-size: 14px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
  content: "16px";
  font-size: 16px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
  content: "18px";
  font-size: 18px !important;
}

.ql-container {
  font-size: 17px;
}
